<template>
  <div class="persona-card">
    <b-dropdown aria-role="list" position="is-top-right" :mobile-modal="false">
      <div
        class="p-3 has-border-radius overflow-hidden shadow-capvital cursor-pointer transform hover:scale-105 transition duration-500 ease-in-out flex flex-col"
        slot="trigger"
        :class="{ active: active }"
        @click="$emit('set-active', index)"
      >
        <p class="font-medium text-sm">
          {{ persona.relacionado.nombre_completo.split("|")[0] }}
        </p>
        <p class="has-text-grey-light text-sm">
          {{ persona.rol.nombre }} #{{ persona.relacionado.id }}
        </p>
        <div class="absolute bottom-0 right-0">
          <icon-initials
            class="transform scale-150 -mr-2 -mb-2 font-bold"
            :nombre="persona.relacionado.nombre_completo.split('|')[0]"
          />
        </div>
      </div>
      <b-dropdown-item has-link aria-role="listitem">
        <a
          @click="goTo(config.client +
            '/#/administracion/personas/ver/perfiles/' +
            persona.relacionado.id)"
        >
          Ver perfil
        </a>
      </b-dropdown-item>
      <b-dropdown-item
        v-if="editable"
        aria-role="listitem"
        class="has-text-danger"
        @click="$emit('delete', index)"
      >
        Eliminar
      </b-dropdown-item>
      <b-dropdown-item
        v-if="add"
        aria-role="listitem"
        class="has-text-success"
        @click="$emit('added', persona.id)"
      >
        Agregar
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import IconInitials from "@/components/generals/IconInitials";
import config from "@/../config";
import { goTo } from "@/vendors/helpers";

export default {
  name: "PersonaCard",
  data() {
    return { config: config };
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    },
    index: Number,
    persona: Object,
    add: {
      type: Boolean,
      default: false
    }
  },
  methods:{
    goTo(url){
      goTo(url);
    }
  },
  components: {
    IconInitials
  }
};
</script>

<style lang="scss">
@import "@/scss/_variables.scss";
.persona-card {
  width: 100%;
  height: 100%;
  .shadow-capvital {
    border: 2px solid $white;
    &.active {
      border: 2px solid $primary;
    }
  }
  > .dropdown {
    width: 100%;
    height: 100%;
    > div[role="button"] {
      width: 100%;
      height: 100%;
      > div {
        height: 100%;
        min-height: 9rem;
      }
    }
  }
  .dropdown-menu {
    min-width: 3rem;
    width: 8rem;
    max-width: 8rem;
    right: -4rem;
    left: auto;
    top: -3rem !important;
    box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.09);
  }
}
</style>
